@import "theme/colors.less";

.modal-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  background: #ffffff;

  &-header {
    & > .title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 700;
      padding: 1.5rem 0;
      border-bottom: 0.5px solid @greyscale-100;
      &.left {
        margin-left: 2.8rem;
        text-align: left;
      }
      &.center {
        text-align: center;
      }
    }

    & > .icon {
      position: absolute;
      cursor: pointer;
      fill: @greyscale-400;
      right: 1.5rem;
      top: 1.5rem;
      z-index: 99;
    }
  }
}

@font-family: "Nunito", sans-serif;
@import "theme/colors.less";

.notification {
  & > .title {
    display: flex;
    justify-content: space-between;

    & > .title_list {
      display: flex;
      gap: 0 1.6rem;

      & > .line {
        border: 1px solid @purple-200;
      }

      & > span {
        font-size: 1.4rem;
        cursor: pointer;
        color: @greyscale-400;
        font-weight: 500;

        &.active {
          color: @purple-500;
          font-weight: bold;
        }
      }
    }

    & > .see_more {
      display: flex;
      gap: 0 0.8rem;
      cursor: pointer;
    }

    &.sticky {
      position: sticky;
      top: 0;
      background: white;
      padding: 1.5rem 0;
    }
  }

  & > .list {
    max-height: calc(70rem - 3rem);
    // max-height: 37.5rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

@font-family: "Nunito", sans-serif;
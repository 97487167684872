@import "theme/colors.less";

.button {
  border-radius: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  border: none;
  outline: none;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 6px;
  width: max-content;
  height: 42px;
  background-color: transparent;

  &:disabled {
    background: @purple-300;
    opacity: 0.75;
    cursor: not-allowed;
  }

  &-primary {
    background: @lightToDark;
    color: white;

    svg {
      path {
        fill: white !important;
      }
    }
  }

  &-none-blue {
    color: @purple-500;
    border: 1px solid @purple-500;
    background-color: transparent;
  }

  &-none-red {
    color: @red;
    border: 1px solid @red;
  }

  &-none-gray {
    color: @greyscale-400;
    border: 1px solid @greyscale-400;
  }

  &-none-gray2 {
    color: @greyscale-600;
    border: 1px solid @greyscale-600;
  }

  &-none {
    color: black;
    border: "none";
  }

  &-error {
    background: @red;
    color: white;
  }

  &.large {
    height: 3rem;
  }

  &.small {
    height: 32px;
  }
}

.w__100 {
  width: 100%;
}
@font-family: "Nunito", sans-serif;
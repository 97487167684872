@import "theme/colors.less";

.sidebar {
  position: sticky;
  margin-top: 6.4rem;
  top: 6.4rem;
  width: 7rem;
  background-color: white;
  padding: 3.2rem 1.5rem;
  min-height: calc(100vh - 6.4rem);
  transition: all 0.25s linear;

  &.show {
    width: 25.5rem;
    padding: 3.2rem 2.4rem;
    transition: all 0.25s linear;
  }

  &.show &_list {

    & .item {
      justify-content: flex-start;

      & span {
        width: max-content;
        display: block;
      }
    }
  }

  &.show &_bottom {
    &>.item {
      justify-content: flex-start;

      &>span {
        width: max-content;
        display: block;
      }

      &>.count {
        width: 2rem;
      }
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem 0;

    & .item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 0 2rem;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        user-select: none;
      }

      &.active,
      &:hover {
        &>.icon {
          fill: @purple-500;
        }

        &>span {
          color: @purple-500;
        }
      }

      &>span {
        overflow: hidden;
        display: none;
        width: 0;
        font-weight: 600;
        font-size: 1.4rem;
        color: @greyscale-700;
        white-space: nowrap;
        transition: width 0.25s linear;
      }
    }
  }

  &_line {
    margin: 2.4rem 0;
    border: 1px solid @greyscale-100;
  }

  &_bottom {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;

    &>.item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 1.6rem;
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        user-select: none;
      }

      &>span {
        overflow: hidden;
        display: none;
        width: 0;
        font-size: 1.4rem;
        font-weight: 600;
        white-space: nowrap;
        transition: width 0.25s linear;
      }

      &>.count {
        &:last-child {
          width: 0;
          height: 2rem;
          line-height: 2rem;
          font-size: 1rem;
          font-weight: bold;
          text-align: center;
          color: @purple-10;
          background: @lightToDark;
          border-radius: 100%;
          transition: width 0.25s linear;
        }
      }

      &.active,
      &:hover {
        &>.icon {
          fill: @purple-500;
        }

        &>span {
          color: @purple-500;
        }
      }
    }
  }
}
@font-family: "Nunito", sans-serif;
@import "theme/colors.less";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: "Nunito", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: @purple-500;
}

#root,
#root>.App {
  height: 100vh;
}

html {
  font-size: 0.625vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  background-color: @background-primary;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

//custom fz for date picker
.MuiCalendarPicker-root {
  font-size: 1.4rem;

  //name days of week
  span {
    font-size: 1.2rem;
  }

  //days
  .PrivatePickersSlideTransition-root {
    button {
      font-size: 1rem;
    }
  }
}

// custom style pagination MUI
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .MuiPaginationItem-text {
    font-size: 1.6rem;
    font-weight: bold;

    &>svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    &.Mui-selected {
      background: @lightToDark;
    }
  }
}

.bg-white {
  background-color: white;
}

.pointer {
  cursor: pointer;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.ju-center {
  justify-content: center;
}

.al-center {
  align-items: center;
}

.ju-between {
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 1.6rem;
}

.small_title {
  font-weight: 700;
  font-size: 1.4rem;
}

.sub_title {
  font-weight: 700;
  font-size: 1.4rem;
  color: @greyscale-500;
}

.text {
  font-size: 1.4rem;
  font-weight: 600;
  color: @greyscale-500;
}

.sub_text {
  font-size: 1.2rem;
  color: @greyscale-400
}

.small_text {
  font-size: 1.2rem;
}

.large_text {
  font-size: 1.6rem;
  font-weight: bold;
  color: @greyscale-600;
}


.total_number {
  font-weight: 700;
  color: @purple-500;
  font-size: 2rem;
}

.small_total_number {
  font-weight: 700;
  color: @purple-500;
  font-size: 1.4rem;
}

.border_top_bottom {
  border: solid black;
  border-width: 0.1rem 0rem;
  border-color: @greyscale-50;
}

a.cta {
  color: @purple-500;
}

.gap {
  &-4 {
    gap: 0.4rem;
  }

  &-8 {
    gap: 0.8rem;
  }

  &-12 {
    gap: 1.2rem;
  }

  &-16 {
    gap: 1.6rem;
  }

  &-20 {
    gap: 2rem;
  }
}

.m {
  &t {
    &-4 {
      margin-top: 0.4rem;
    }

    &-8 {
      margin-top: 0.8rem;
    }

    &-12 {
      margin-top: 1.2rem;
    }

    &-16 {
      margin-top: 1.6rem;
    }

    &-20 {
      margin-top: 2rem;
    }
  }

  &b {
    &-4 {
      margin-bottom: 0.4rem;
    }

    &-8 {
      margin-bottom: 0.8rem;
    }

    &-12 {
      margin-bottom: 1.2rem;
    }

    &-16 {
      margin-bottom: 1.6rem;
    }

    &-20 {
      margin-bottom: 2rem;
    }
  }

  &l {
    &-4 {
      margin-left: 0.4rem;
    }

    &-8 {
      margin-left: 0.8rem;
    }

    &-12 {
      margin-left: 1.2rem;
    }

    &-16 {
      margin-left: 1.6rem;
    }

    &-20 {
      margin-left: 2rem;
    }
  }

  &r {
    &-4 {
      margin-right: 0.4rem;
    }

    &-8 {
      margin-right: 0.8rem;
    }

    &-12 {
      margin-right: 1.2rem;
    }

    &-16 {
      margin-right: 1.6rem;
    }

    &-20 {
      margin-right: 2rem;
    }
  }
}

.p {
  &t {
    &-4 {
      padding-top: 0.4rem;
    }

    &-8 {
      padding-top: 0.8rem;
    }

    &-12 {
      padding-top: 1.2rem;
    }

    &-16 {
      padding-top: 1.6rem;
    }

    &-20 {
      padding-top: 2rem;
    }
  }

  &b {
    &-4 {
      padding-bottom: 0.4rem;
    }

    &-8 {
      padding-bottom: 0.8rem;
    }

    &-12 {
      padding-bottom: 1.2rem;
    }

    &-16 {
      padding-bottom: 1.6rem;
    }

    &-20 {
      padding-bottom: 2rem;
    }
  }

  &l {
    &-4 {
      padding-left: 0.4rem;
    }

    &-8 {
      padding-left: 0.8rem;
    }

    &-12 {
      padding-left: 1.2rem;
    }

    &-16 {
      padding-left: 1.6rem;
    }

    &-20 {
      padding-left: 2rem;
    }
  }

  &r {
    &-4 {
      padding-right: 0.4rem;
    }

    &-8 {
      padding-right: 0.8rem;
    }

    &-12 {
      padding-right: 1.2rem;
    }

    &-16 {
      padding-right: 1.6rem;
    }

    &-20 {
      padding-right: 2rem;
    }
  }
}
@font-family: "Nunito", sans-serif;
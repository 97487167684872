@import "theme/colors.less";

.popup-keyword {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem 0;
  padding: 2.4rem;
  max-width: 33.5rem;
  background-color: white;

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px 0;

    & > img {
      width: 123px;
      object-fit: cover;
      margin-bottom: 8px;
    }

    & > .title {
      color: @purple-800;
      font-weight: bold;
      font-size: 1.6rem;
    }

    & > .keywords {
      & > span {
        font-size: 1.4rem;
        color: @gray-150;
        word-break: break-all;
      }
    }

    & > .description {
      color: @gray-950;
      font-size: 1.4rem;
    }
  }
}

@font-family: "Nunito", sans-serif;
@import "theme/colors.less";

.data-none-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 2rem;
  margin: 2rem 0;

  & > span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
    color: @greyscale-400;
    text-align: center;
  }
}

@font-family: "Nunito", sans-serif;
@import "theme/colors.less";

.modal-news {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.6rem;

  img {
    width: 100%;
    height: max-content;
  }

  & > .title {
    color: @greyscale-600;
    font-size: 1.8rem;
    font-weight: bold;
  }

  & > .time {
    display: flex;
    align-items: center;
    gap: 0 1rem;

    & > span {
      font-size: 1.2rem;
      color: @greyscale-300;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 0.8rem 0;
  }
}

@font-family: "Nunito", sans-serif;
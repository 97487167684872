.layout {
  display: flex;
  justify-content: space-between;

  &.isSidebar &_children {
    width: calc(100% - 24rem);
  }

  &_children {
    min-width: 100rem;
    width: calc(100% - 7rem);
    padding: 2.4rem 4rem;
    margin-top: 6.4rem;
    transition: width 0.25s linear;
  }
}
@font-family: "Nunito", sans-serif;
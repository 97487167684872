@import "theme/colors.less";

.message-item {
  cursor: pointer;
  display: flex;
  padding: 1.6rem 2rem;

  &.active,
  &:hover {
    background-color: @purple-10;
  }
  &.active {
    & > .user {
      & > span {
        font-weight: 700;
      }
    }
  }

  & > img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 0.8rem;
  }

  & > .user {
    width: 23.4rem;
    margin-left: 1.2rem;

    & > p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:first-child {
        font-weight: bold;
        margin-bottom: 0.4rem;
      }
    }

    & > .time {
      margin-top: 0.8rem;
      display: flex;
      align-items: flex-start !important;
      // align-items: flex-end;

      gap: 0 0.8rem;

      & > span {
        color: @greyscale-400;
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
    }
  }

  & > .more {
    cursor: pointer;
    margin-left: auto;
    & > svg {
      width: 0.5rem;
      height: 1.5rem;
    }
  }
}

@font-family: "Nunito", sans-serif;
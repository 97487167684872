@import "theme/colors.less";

.notification-item {
  cursor: pointer;
  display: flex;
  gap: 1.2rem;

  padding: 1.6rem 2rem;

  &.active,
  &:hover {
    background-color: @purple-10;
  }

  & > img {
    width: 5.2rem;
    height: 5.2rem;
    object-fit: cover;
    border-radius: 0.8rem;
  }

  & > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & > .title {
      line-height: 2rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & > .time {
      display: flex;
      align-items: flex-end;
      gap: 0 0.8rem;

      & > span {
        color: @greyscale-400;
        font-size: 1.2rem;
      }
    }
  }

  & > .more {
    cursor: pointer;
    margin-left: auto;
    & > svg {
      width: 0.5rem;
      height: 1.5rem;
    }
  }
}

@font-family: "Nunito", sans-serif;
@import "theme/colors.less";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.5rem;
  height: 6.4rem;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  z-index: 80;

  &_left {
    display: flex;
    gap: 0 2.5rem;
    align-items: center;

    &>.icon {
      cursor: pointer;
    }

    &>img {
      cursor: pointer;
      width: 7.2rem;
    }
  }

  &_right {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0 3rem;

    &>.icon {
      position: relative;
      cursor: pointer;

      &>span {
        position: absolute;
        bottom: calc(100% - 1rem);
        right: calc(0% - 1rem);
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.2rem;
        border-radius: 100%;
        text-align: center;
        background: @lightToDark;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        border: 2px solid white;
      }
    }

    &>.notificationShow {
      padding: 0 1.5rem;
      border-radius: 12px;

      position: absolute;

      top: 6rem;
      right: 0;
      z-index: 99;

      // max-width: 40.3rem;
      width: 40.3rem;
      max-height: 46.9rem;

      height: fit-content;

      background: white;

      box-shadow: 0px 8px 16px 0px #0117710f;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &>.avatar {
      display: flex;
      align-items: center;
      gap: 0 1.8rem;
      cursor: pointer;

      &>span {
        font-weight: bold;
        font-size: 1.6rem;
      }
    }

    &>.overlay {
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &>.children {
      position: absolute;
      top: calc(100% + 3rem);
      background-color: white;
      width: 30rem;
      z-index: 2;
      right: 0;
      border-radius: 1.6rem;
      padding: 1.6rem;
      display: flex;
      flex-direction: column;
      gap: 3.2rem 0;
      box-shadow: 0px 8px 16px rgba(1, 23, 113, 0.06);

      &>span {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
      }

      &>p {
        cursor: pointer;
        font-size: 1.5rem;

        &:last-child {
          padding-top: 3.2rem;
          border-top: 1px solid @greyscale-100;
        }
      }
    }
  }
}
@font-family: "Nunito", sans-serif;